
const contactForm = document.querySelector('.contact-form');
if (contactForm) {
    function checkRequiredFields() {
        // 'required' クラスを持つ要素を全て取得
        let requiredElems = document.querySelectorAll('.required');
        let allFilled = true;

        requiredElems.forEach((elem) => {
            // elemが input または textarea の場合
            if (elem.tagName === 'INPUT' || elem.tagName === 'TEXTAREA') {
                // elemが radio button の場合
                if (elem.type === 'radio') {
                    // 同じ名前のradio buttonでチェックされているものがあるか確認
                    let radioButtons = document.querySelectorAll(`input[type='radio'][name='${elem.name}']`);
                    let isAnyChecked = [...radioButtons].some(radio => radio.checked);
                    if (!isAnyChecked) {
                        allFilled = false;
                    }
                } else { // elemが text, textarea, または checkbox の場合
                    if (elem.value === '') {
                        allFilled = false;
                    }
                }
            }
        });

        // 全ての required field に値があればボタンをアクティブにする
        const submitButton = document.querySelector('.contact-form__confirm-btn');
        submitButton.classList.toggle('active', allFilled);
    }

    // ページが読み込まれたら実行
    window.onload = checkRequiredFields;

    // 任意の input または textarea の内容が変わったら再度チェック
    document.querySelectorAll('.required').forEach((elem) => {
        elem.addEventListener('input', checkRequiredFields);
        if (elem.tagName === 'INPUT' && elem.type === 'radio') {
            elem.addEventListener('change', checkRequiredFields);
        }
    });
}





// contact-form__confirm-btnがクリックされたら、全てのinput,textareaの内容を表示する
const confirmBtn = document.querySelector('.contact-form__confirm-btn');
if (confirmBtn) {
    confirmBtn.addEventListener('click', (e) => {
        e.preventDefault();
        const contactFormInner = document.querySelector('.contact-form__inner');
        const inputs = contactFormInner.querySelectorAll('input');
        const textarea = contactFormInner.querySelectorAll('textarea');
        const allInputs = [...inputs, ...textarea];
        allInputs.forEach((input) => {
            const inputName = input.name;
            if (input.type !== "radio" ) {
                const inputConfirm = document.querySelector(`#${inputName}-text`);
                inputConfirm.textContent = input.value;
            } else {
                const inputConfirm = document.querySelector(`#${inputName}-text`);
                const inputChecked = document.querySelector(`input[name='${inputName}']:checked`);
                inputConfirm.textContent = inputChecked.value;
            }
        });
        contactFormInner.classList.add('contact-form__inner--hidden');
        const contactFormConfirm = document.querySelector('.contact-form__confirm');
        contactFormConfirm.classList.remove('contact-form__confirm--hidden');
        window.scrollTo(0, 0);
    });

    const backBtn = document.querySelector('.contact-form__back-btn');
    backBtn.addEventListener('click', (e) => {
        e.preventDefault();
        const contactFormInner = document.querySelector('.contact-form__inner');
        contactFormInner.classList.remove('contact-form__inner--hidden');
        const contactFormConfirm = document.querySelector('.contact-form__confirm');
        contactFormConfirm.classList.add('contact-form__confirm--hidden');
        window.scrollTo(0, 0);
    });
}






