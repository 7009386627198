import Swiper from "swiper/bundle";


// 子要素にクラス付与がっ必要なための処理
const swiperChildrenCreate = (inner, items) => {
    inner.classList.add("swiper-wrapper");
    items.forEach(function (item) {
        item.classList.add("swiper-slide");
    });
};

const topNewsList = document.querySelector(".top-news__list");
if (topNewsList) {
    const topNewsListInner = topNewsList.querySelector(".top-news__list-inner");
    const topNewsItems = topNewsList.querySelectorAll(".top-news__item");
    swiperChildrenCreate(topNewsListInner, topNewsItems);
}

const topMainVisual = document.querySelector(".mainvisual");
if (topMainVisual) {
    const topMainVisualList = topMainVisual.querySelector(".mainvisual__list");
    const topMainVisualItem = topMainVisual.querySelectorAll(".mainvisual__item");
    swiperChildrenCreate(topMainVisualList, topMainVisualItem);
}

const recruitInterviewList = document.querySelector(".recruit-interview__list");
if (recruitInterviewList) {
    const recruitInterviewListInner = recruitInterviewList.querySelector(".recruit-interview__list-inner");
    const recruitInterviewItems = recruitInterviewList.querySelectorAll(".recruit-interview__item");
    swiperChildrenCreate(recruitInterviewListInner, recruitInterviewItems);
}

const recruitReasonList = document.querySelector(".recruit-reason__img-list");
if (recruitReasonList) {
    const recruitReasonListInner = recruitReasonList.querySelector(".recruit-reason__img-list-inner");
    const recruitReasonItems = recruitReasonList.querySelectorAll(".recruit-reason__img-item");
    swiperChildrenCreate(recruitReasonListInner, recruitReasonItems);
}


// スマホ時のみのスライダーの処理ーーーーーーーーーーーーー
const breakPoint = 768;
let swiper;
let swiperBool;

window.addEventListener("load",() => {
    if (breakPoint < window.innerWidth) {
        swiperBool = false;
    } else {
        createSwiper();
        swiperBool = true;
    }
});

window.addEventListener("resize",() => {
    if (breakPoint < window.innerWidth && swiperBool) {
        swiper.destroy(false, true);
        swiperBool = false;
    } else if (breakPoint >= window.innerWidth && !swiperBool) {
        createSwiper();
        swiperBool = true;
    }
});
// スマホ時のみのスライダーの処理ーーーーーーーーーーーーー

const options = {
    loop: true,
    speed: 2000,
    autoplay: {
        delay: 2000,
    },
    effect: "fade",
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
}

const optionsRecruitReasonList = {
    loop: true,
    speed: 1500,
    autoplay: {
        delay: 1500,
    },
    effect: "fade",
}




// ここに追加
swiper = new Swiper(".mainvisual", options);
swiper = new Swiper(".recruit-reason__img-list", optionsRecruitReasonList);
const createSwiper = () => {
    swiper = new Swiper(".top-news__list", {});
    swiper = new Swiper(".recruit-interview__list", {});
};

